import { getHasFilledShippingAddress } from './../../../store/login/reducers';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Button from '../../global/Button'
import LocalizedLink from '../../global/LocalizedLink'
import Translation from '../../global/Translation'
import InputNumber from '../../global/InputNumber'
import React from 'react'
import Alerts from '../../alerts/Alerts'

export const RenderProductSingleActions = ({
                                         original,
                                         intl,
                                         viewEdit,
                                         isLogged,
                                         userName,
                                         currentUserIsSeller,
                                         isVerified,
                                         addingToCart,
                                         isLoading,
                                         bankData,
                                         onShowUpLogin,
                                         addToCart,
                                         deleteItem,
                                     }) => {
    const addressData = useSelector((state) => getHasFilledShippingAddress(state))

    // no esata logeado
    if (!isLogged) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                }}
            >
                <Button icon='material-icons-outlined' onClick={onShowUpLogin}>
                        <span className='material-icons-outlined' style={{ fontSize: '24px' }}>
                            login
                        </span>
                </Button>
            </div>
        )
    }

    // no esta verificado
    if (!isVerified) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                }}
            >
                <LocalizedLink routeId={'ROUTE_RESEND_VERIFICATION_MAIL_PAGE'}>
                    <Button icon='edit'>
                        <Translation id={'VERIFY_YOUR_EMAIL'} />
                    </Button>
                </LocalizedLink>
            </div>
        )
    }

    const routeId = intl.formatMessage({ id: 'ROUTE_USER_PROFILE' }).replace('/:view?', '#shipping-information')

    if (!addressData) {
        return (
            <div className="col-12">
                <Alerts alert={'SHIPPING_ADDRESS_REQUIRED'} status={'warning'} routeId={routeId} />
            </div>
        )
    }
    // // no tiene informacion bancaria
    // if (!bankData || !bankData.validated) {
    //     const routeId = intl.formatMessage({ id: 'ROUTE_USER_PROFILE' }).replace(':view?', 'financial-information')
    //     return (
    //         <div
    //             style={{
    //                 display: 'flex',
    //                 flexDirection: 'row',
    //                 alignItems: 'center',
    //                 justifyContent: 'space-evenly'
    //             }}
    //         >
    //             <LocalizedLink routeId={routeId}>
    //                 <Button icon='edit'>
    //                     <Translation id={'FILL_FINANCIAL_INFO_TO_TRADE'} />
    //                 </Button>
    //             </LocalizedLink>
    //         </div>
    //     )
    // }

    // es el vendedor
    if (currentUserIsSeller) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                {original.blocked && (
                    <i className="fa fa-exclamation-triangle" data-tip data-for={'blocked-' + original.id} style={{color:'#666666'}}>
                        <ReactTooltip id={'blocked-' + original.id} effect="solid">
                            {original.qtyBlocked} <Translation id="x_blocked_items" defaultMessage="Items are on someone cart" />
                        </ReactTooltip>
                    </i>
                )}

                {deleteItem && (
                    <Button icon="trash" size="small" secondary onClick={() => deleteItem(original.id, original)}>
                        <Translation id="Delete" defaultMessage="delete" />
                    </Button>
                )}
                <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} params={{ view: viewEdit, id: original.id }}>
                    <Button icon="edit" size="small">
                        <Translation id="Edit" defaultMessage="edit" />
                    </Button>
                </LocalizedLink>
            </div>
        )
    }

    let qtyToAdd = 1
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px'
            }}
        >
            {addToCart && (
                <React.Fragment>
                    <InputNumber value={1} key={original.id + '-' + qtyToAdd} change={(qty) => (qtyToAdd = qty)}
                                 limit={original.qty}></InputNumber>
                    <Button
                        // icon="shopping-cart"
                        onClick={() => addToCart(original.id, qtyToAdd)}
                        disabled={addingToCart || isLoading  || original.qty == 0}
                    >
                        {original.type === 'SALE' &&
                            <Translation id='buy' defaultMessage='Buy' />
                        }
                        {original.type === 'BUY' &&
                            <Translation id='sale' defaultMessage='Sale' />
                        }
                    </Button>
                </React.Fragment>
            ) }
        </div>
    )


}
